const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_identity_pool_id": "us-west-2:df85bd58-2b3f-4e36-8cdc-b72c43ba13de",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_oARA3sqaA",
  "aws_user_pools_web_client_id": "2rfgtnvbv9q2of7fkojtqv04f4",
  "oauth": {
    "domain": "empath-auth-testing.auth.us-west-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "responseType": "code",
    "redirectSignIn": "https://testing.empath.io/login",
    "redirectSignOut": "https://testing.empath.io/logout"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://iq3liito2bdsdfxrshtio6mtda.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_mobile_analytics_app_id": "62d968bdcdfb4eeca142f36aad8bf33b",
  "aws_mobile_analytics_app_region": "us-west-2",
  "aws_user_files_s3_bucket": "empath-testing-us-west-2-storage",
  "aws_user_files_s3_bucket_region": "us-west-2",
  "cookieStorage": {
    "domain": ".empath.io",
    "secure": true
  }
}; 

export default awsmobile;